import { FC, useEffect, useState } from "react";
import { CalendarButton } from "./CalendarButton";
import { Album, Songs, songsData } from "./SongData";
import {
  ButtonContainer,
  Container,
  Content,
  TextStyling,
  TodaysSong,
} from "./Start.styles";
import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface IProps {}

export const Start: FC<IProps> = (props) => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  function findSongForToday(songsData: Album[]): Album {
    // Get today's date
    const today = new Date();
    const todayMonth = today.getMonth(); // Month is zero-based, so add 1
    const todayDay = today.getDate();

    // Iterate over each album
    for (const album of songsData) {
      // Iterate over songs in the album
      for (const song of album.songs) {
        // Check if the song matches today's date
        if (song.month === todayMonth && song.day === todayDay) {
          // Create a new Album object with only the matching song
          const todayAlbum: Album = {
            album: album.album,
            path: album.path,
            songs: [song],
          };
          return todayAlbum;
        }
      }
    }
    return songsData[0];
  }

  // Example usage:
  const todaysAlbum = findSongForToday(songsData);
  console.log(todaysAlbum.songs[0].song);

  return (
    <Container
      container
      flexDirection="column"
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Content
        container
        flexDirection={"column"}
        overflow={"auto"}
        wrap="nowrap"
      >
        <TodaysSong
          to={todaysAlbum.path}
          state={{
            ...todaysAlbum,
            songs: todaysAlbum.songs[0],
          }}
        >
          <TextStyling>Dagens låt</TextStyling>
        </TodaysSong>
        <Typography paddingTop={"1rem"} fontSize="2rem" textAlign={"center"}>
          The Eras Tour Setlist
        </Typography>
        <ButtonContainer container>
          {songsData.map((x) => (
            <>
              {x.songs.map((z, index) => (
                <>
                  <CalendarButton index={index} path={x.path} album={x} />
                </>
              ))}
            </>
          ))}
        </ButtonContainer>
      </Content>
    </Container>
  );
};
