import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import { Song } from "./Era/Song";
import ScrollToTop from "./ScrollToTop";
import { Start } from "./Start";
import theme from "./theme";

const Pages = () => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Start />} />
        <Route path="/fearless" element={<Song />} />
        <Route path="/speaknow" element={<Song />} />
        <Route path="/red" element={<Song />} />
        <Route path="/1989" element={<Song />} />
        <Route path="/reputation" element={<Song />} />
        <Route path="/lover" element={<Song />} />
        <Route path="/folklore" element={<Song />} />
        <Route path="/evermore" element={<Song />} />
        <Route path="/midnights" element={<Song />} />
      </Routes>
    </>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <Router>
          <ScrollToTop />
          <Pages />
        </Router>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
