import { Button, Grid, Typography, styled } from "@mui/material";
import Tour from "../src/Images/tour.jpeg";
import { Link } from "react-router-dom";

export const StyledButton = styled(Button)(({ theme }) => ({
  color: "black",
  textDecoration: "none",
  textAlign: "center",
  height: "fit-content",
  backgroundColor: "white",
  padding: "0.5rem 1rem",
})) as typeof Button;

export const ButtonContainer = styled(Grid)(({ theme }) => ({
  padding: "2rem",
  paddingTop: 0,
  gap: "1rem",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    padding: "0.5rem",
    gap: "0.5rem",
  },
}));

export const Container = styled(Grid)(({ theme }) => ({
  backgroundImage: "url(" + Tour + ")",
  minHeight: "100vh",
  color: "white",
}));

export const Content = styled(Grid)(({ theme }) => ({
  backgroundColor: "rgba(22,30,55,0.8)",
  padding: "2rem",
  gap: "1rem",
  justifyContent: "center",
  alignItems: "center",
}));

export const TodaysSong = styled(Link)(({ theme }) => ({
  border: "1px solid rgb(165,184,244)",
  padding: "1rem",
  color: "rgb(165,184,244)",
  textDecoration: "none",
  borderRadius: "0.5rem",
  backgroundColor: "rgba(22,30,55,0.8)",
  fontSize: "2rem",
  boxShadow: `inset 0 0 0 .2em rgba(22,30,55,0.8),inset 0 0 1em .3em rgba(22,30,55,0.8),0 0 0 .2em rgba(22,30,55,0.8),0 0 1em .3em rgba(22,30,55,0.8),1em 1em .4em rgba(0,0,0,0.6)`,
}));

export const TextStyling = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  color: "rgb(165,184,244)",
}));
