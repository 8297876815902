import { Favorite, Lock } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";
import { FC, useEffect, useState } from "react";
import { StyledButton, StyledLink } from "./CalendarButton.styles";
import { Thirteen } from "./Icons/13";
import { Bird } from "./Icons/Bird";
import { Cardigan } from "./Icons/Cardigan";
import { Champagne } from "./Icons/Champagne";
import { CowBoyHat } from "./Icons/CowBoyHat";
import { Midnight } from "./Icons/Midnight";
import { Scarf } from "./Icons/Scarf";
import { Snake } from "./Icons/Snake";
import { Album } from "./SongData";
import theme from "./theme";
interface IProps {
  album: Album;
  index: number;
  path: string;
}

export const CalendarButton: FC<IProps> = (props) => {
  let currentDate = new Date();
  const [date, setDate] = useState(new Date());
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 100000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const checkDisabled = (day?: number, month?: number) => {
    if (month !== undefined && day !== undefined) {
      if (month > currentDate.getMonth()) {
        return true;
      }
      if (month === currentDate.getMonth() && day > currentDate.getDate()) {
        return true;
      }
    }
    return false;
  };

  const checkTodaysButton = (day?: number, month?: number) => {
    if (month !== undefined && day !== undefined) {
      if (currentDate.getMonth() === month && currentDate.getDate() === day) {
        return true;
      }
    }
    return false;
  };

  const getSongDay = () => {
    return props.album.songs.find((x, index) => props.index === index)?.day;
  };

  const getSongMonth = () => {
    return props.album.songs.find((x, index) => props.index === index)?.month;
  };

  const getSongAlbum = () => {
    return props.album.album;
  };

  interface IMotionDiv {
    children: any;
  }

  const MotionDiv = (props: IMotionDiv) => {
    if (checkTodaysButton()) {
      return (
        <motion.div
          className="box"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.8,
            delay: 0.3,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          {props.children}
        </motion.div>
      );
    }
    return <></>;
  };

  const albumIcon = () => {
    switch (getSongAlbum()) {
      case "Fearless":
        return <CowBoyHat />;
      case "Speak Now":
        return <Thirteen />;
      case "Red":
        return <Scarf />;
      case "1989":
        return <Bird />;
      case "reputation":
        return <Snake />;
      case "Lover":
        return <Favorite />;
      case "folklore":
        return <Cardigan />;
      case "evermore":
        return <Champagne />;
      case "Midnights":
        return <Midnight />;
    }
  };

  return (
    <>
      <StyledLink
        disabled={checkDisabled(getSongDay(), getSongMonth())}
        to={props.path}
        state={{
          ...props.album,
          songs: props.album.songs.find((x, index) => props.index === index),
        }}
      >
        <StyledButton
          disabled={checkDisabled(getSongDay(), getSongMonth())}
          era={props.album.album}
          /* todaysButton={checkTodaysButton(getSongDay(), getSongMonth())} */
        >
          {checkDisabled(getSongDay(), getSongMonth()) ? <Lock /> : albumIcon()}
          {/* {albumIcon()} */}
        </StyledButton>
      </StyledLink>
    </>
  );
};
