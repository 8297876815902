import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const Scarf = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="800px"
        height="800px"
        viewBox="0 0 24 24"
        id="Layer_1"
        data-name="Layer 1"
        fill="#FFFFFF"
      >
        <defs></defs>
        <rect x="1.5" y="5.34" width="21" height="7.64" rx="1.91" />
        <path d="M3.41,13H11a0,0,0,0,1,0,0V18.7a1.91,1.91,0,0,1-1.91,1.91H5.32A1.91,1.91,0,0,1,3.41,18.7V13A0,0,0,0,1,3.41,13Z" />
        <line x1="7.23" y1="23.48" x2="7.23" y2="20.61" />
        <line x1="3.41" y1="23.48" x2="3.41" y2="18.7" />
        <line x1="11.05" y1="23.48" x2="11.05" y2="18.7" />
        <path d="M6.27,1.52H17.73a2.86,2.86,0,0,1,2.86,2.86v1a0,0,0,0,1,0,0H3.41a0,0,0,0,1,0,0v-1A2.86,2.86,0,0,1,6.27,1.52Z" />
      </svg>
    </SvgIcon>
  );
};
