export interface Album {
  album:
    | "Fearless"
    | "Speak Now"
    | "Red"
    | "1989"
    | "reputation"
    | "Lover"
    | "folklore"
    | "evermore"
    | "Midnights";
  songs: Songs[];
  path: string;
}

export interface Songs {
  song: string;
  link: string;
  interaction?: string;
  funfact?: string;
  about?: string;
  month: number;
  day: number;
  lyrics?: string;
}
/* 5:e april */
export const songsData: Album[] = [
  {
    album: "Lover",
    path: "/lover",
    songs: [
      {
        song: "Miss Americana & The Heartbreak King",
        link: "https://open.spotify.com/track/214nt20w5wOxJnY462klLw?si=2fa0e36da52b477f",
        month: 4,
        day: 16,
        about: "Taylors försök till en politisk låt.",
      },
      {
        song: "Cruel Summer",
        link: "https://open.spotify.com/track/1BxfuPKGuaTgP7aM0Bbdwr?si=dd04055f124f40cf",
        month: 3,
        day: 6,

        funfact:
          "Växte så mycket i populäritet 2023 att hon släppte den som singel och nu är det hennes mest spelade låt.",
      },
      {
        song: "The Man",
        link: "https://open.spotify.com/track/3RauEVgRgj1IuWdJ9fDs70?si=ca79c895348943e6",
        month: 4,
        day: 10,
        about:
          "It's about what my career or headlines would have been like if I was a man instead of a woman. - Taylor Swift",
      },
      {
        song: "You Need To Calm Down",
        link: "https://open.spotify.com/track/6RRNNciQGZEXnqk8SQ9yv5?si=0a64505f51874156",
        month: 3,
        day: 25,
        funfact: "Gay anthem",
      },
      {
        song: "Lover",
        link: "https://open.spotify.com/track/1dGr1c8CrMLDpV6mPbImSI?si=48160e352c2a4501",
        month: 3,
        day: 21,
        about: "Joe Alwyn",
      },
      {
        song: "The Archer",
        link: "https://open.spotify.com/track/3pHkh7d0lzM2AldUtz2x37?si=3b5bf2fb47cd492e",
        month: 3,
        day: 17,
        lyrics: "Who could ever leave me, darling? But who could stay?",
      },
    ],
  },
  {
    album: "Fearless",
    path: "/fearless",
    songs: [
      {
        song: "Fearless",
        link: "https://open.spotify.com/track/77sMIMlNaSURUAXq5coCxE?si=7f12f8008a1644ff",
        month: 3,
        day: 28,
        lyrics:
          "'Cause I don't know how it gets better than this. You take my hand and drag me head first. Fearless",
      },
      {
        song: "You Belong With Me",
        link: "https://open.spotify.com/track/1qrpoAMXodY6895hGKoUpA?si=4c76bd8c16f54741",
        interaction: "Klappa 2 gånger vid 2:45",
        month: 3,
        day: 20,
      },
      {
        song: "Love Story",
        link: "https://open.spotify.com/track/3CeCwYWvdfXbZLXFhBrbnf?si=4a0f7d4e4a394fc1",
        month: 3,
        day: 8,
        about: "En låt som inte behöver någon introduktion. Oldie but goldie.",
      },
    ],
  },
  {
    album: "evermore",
    path: "/evermore",
    songs: [
      {
        song: "'tis damn season",
        link: "https://open.spotify.com/track/7dW84mWkdWE5a6lFWxJCBG?si=27654c3f37cd47ad",
        month: 3,
        day: 14,
        about:
          "Handar om Dorothea som kommer hem till sin hemstad under semestern och reconnectar med en gammal kärlek för helgen.",
      },
      {
        song: "willow",
        link: "https://open.spotify.com/track/0lx2cLdOt3piJbcaXIV74f?si=c8b44b6f4eee45fc",
        month: 3,
        day: 9,
        lyrics:
          "Like you were a trophy or a champion ring. And there was one prize I'd cheat to win",
      },
      {
        song: "marjorie",
        link: "https://open.spotify.com/track/12ntTeqEeTg7GAVpe8Mhpl?si=27515df25c3347ee",
        month: 4,
        day: 13,
        interaction: "Tända ficklamporna",
        about:
          "Marjorie va Taylors mormor som drömde om att bli opera sångerska. Det är hennes sång man kan höra i bakgrunden på låten.",
      },
      {
        song: "champagne problems",
        link: "https://open.spotify.com/track/0sY6ZUTh4yoctD8VIXz339?si=765057352e884a3d",
        month: 3,
        day: 18,
        funfact:
          "Efter att låten släpptes erbjuder nu ett franskt lyxhotell ett paket som heter 'Champagne Solution' som innehåller champagne flaskan som nämns i låten.",
      },
      {
        song: "tolerate it",
        link: "https://open.spotify.com/track/0PurA4JVJ8YQgSVopY8fn6?si=3d86ac1e167948b9",
        month: 3,
        day: 27,
        lyrics:
          "I wait by the door like I'm just a kid. Use my best colors for your portrait. Lay the table with the fancy shit. And watch you tolerate it",
      },
    ],
  },
  {
    album: "reputation",
    path: "/reputation",
    songs: [
      {
        song: "...Ready For It?",
        link: "https://open.spotify.com/track/2yLa0QULdQr0qAIvVwN6B5?si=4107280658ab4863",
        month: 3,
        day: 30,
        lyrics:
          "Knew I was a robber first time that he saw me. Stealing hearts and running off and never saying sorry. But if I'm a thief, then he can join the heist",
      },
      {
        song: "Delicate",
        link: "https://open.spotify.com/track/6NFyWDv5CjfwuzoCkw47Xf?si=61dbd4372fb749c5",
        month: 3,
        day: 5,
        interaction: "1,2,3 let's go bitch vid 0:17",
      },
      {
        song: "Don't Blame Me",
        link: "https://open.spotify.com/track/1R0a2iXumgCiFb7HEZ7gUE?si=96b7992a6abf48de",
        month: 3,
        day: 19,
        about:
          "Splittrat om den handlar om Joe Alwyn och medias perception av hennes.",
      },
      {
        song: "Look What You Made Me Do",
        link: "https://open.spotify.com/track/1P17dC1amhFzptugyAO7Il?si=fbf54bdaa3b244f9",
        month: 4,
        day: 3,
        about:
          "Hennes comeback single efter the dark years. Låten är riktagt mot Kanye West och Kim Kardashian",
      },
    ],
  },
  {
    album: "Speak Now",
    path: "/speaknow",
    songs: [
      {
        song: "Enchanted",
        link: "https://open.spotify.com/track/3sW3oSbzsfecv9XoUdGs7h?si=8d1f21061ea44d59",
        month: 4,
        day: 2,
        lyrics: "This night is sparkling, don't you let it go",
      },
      {
        song: "Long Live",
        link: "https://open.spotify.com/track/4hqJ4bSlYJOXb6Z4SRmzxs?si=29dec9c4910c49fb",
        month: 3,
        day: 24,
        about: "Taylor kärleksbrev till hennes fans.",
      },
    ],
  },
  {
    album: "Red",
    path: "/red",
    songs: [
      {
        song: "We Are Never Getting Back Together",
        link: "https://open.spotify.com/track/5YqltLsjdqFtvqE7Nrysvs?si=5cf5ca5d896d4686",
        month: 4,
        day: 4,
        about: "Jake Gyllenhaal",
      },
      {
        song: "I Knew You Were Trouble",
        link: "https://open.spotify.com/track/6AtZLIzUINvExIUy4QhdjP?si=6aef38ec2c13441a",
        month: 4,
        day: 8,
        about: "Harry Styles",
      },
      {
        song: "22",
        link: "https://open.spotify.com/track/3yII7UwgLF6K5zW3xad3MP?si=eb9976ca5a1e4940",
        month: 3,
        day: 22,
        interaction:
          "Taylor har på sig en svart hatt under låten som hon seddan ger bort till ett utvalt fan.",
      },
      {
        song: "All Too Well (10 Minute Version)",
        link: "https://open.spotify.com/track/5enxwA8aAbwZbf5qCHORXi?si=9b6d5250f56c4e85",
        month: 3,
        day: 10,
        about: "Taylors worst ex: Jake Gyllenhaal.",
        funfact:
          "När Taylor först släppte All To Well råkade hon nämna att den från början va 10 minuter lång. Fansen släppte inte detta och bad henne släppa hela versionen. Så precis det gjorde hon när det va dags att släppa Red (Taylors Version).",
      },
    ],
  },
  {
    album: "folklore",
    path: "/folklore",
    songs: [
      {
        song: "the 1",
        link: "https://open.spotify.com/track/0Jlcvv8IykzHaSmj49uNW8?si=6857807ec9414254",
        month: 4,
        day: 1,
        lyrics: "It would've been fun. If you would've been the one",
      },
      {
        song: "betty",
        link: "https://open.spotify.com/track/5kI4eCXXzyuIUXjQra0Cxi?si=5a1e1baee0b9455a",
        month: 4,
        day: 6,
        about: "Andra låten i The Folklore Love Triangle, ur James perspektiv",
        funfact:
          "De 3 namnen som nämns i låten (Betty, Inez och James) är namnen på Blake Lively & Ryan Reynolds barn.",
      },
      {
        song: "the last great american dynasty",
        link: "https://open.spotify.com/track/2Eeur20xVqfUoM3Q7EFPFt?si=f82f24c4859b4048",
        month: 3,
        day: 16,
        about: "Rebekah Harkness",
      },
      {
        song: "august",
        link: "https://open.spotify.com/track/3hUxzQpSfdDqwM3ZTFQY0K?si=b255bdb84f6b4362",
        month: 4,
        day: 5,
        about:
          "Första låten i The Folklore Love Triangle, ur Augustines perspektiv.",
      },
      {
        song: "illicit affairs",
        link: "https://open.spotify.com/track/2NmsngXHeC1GQ9wWrzhOMf?si=6f2363fd3a9e4595",
        month: 3,
        day: 11,
        lyrics:
          "And you wanna scream: 'Don't call me kid, don't call me baby'. Look at this godforsaken mess that you made me",
      },
      {
        song: "my tears ricochet",
        link: "https://open.spotify.com/track/1MgV7FIyNxIG7WzMRJV5HC?si=3a3dd1e8567a4357",
        month: 4,
        day: 12,
        about:
          "Scott Borchetta, presidenten för Taylors första skivbolag som sålde hennes masters till Scooter Braun.",
      },
      {
        song: "cardigan",
        link: "https://open.spotify.com/track/4R2kfaDFhslZEMJqAFNpdd?si=dda865175d0848b4",
        month: 4,
        day: 7,
        about:
          "Sista låten i The Folklore Love Triangle, ur Bettys perspektiv.",
      },
    ],
  },

  {
    album: "1989",
    path: "/1989",
    songs: [
      {
        song: "Style",
        link: "https://open.spotify.com/track/1hjRhYpWyqDpPahmSlUTlc?si=d79cca4078a446fd",
        month: 3,
        day: 13,
        about: "Harry Styles (chocking)",
      },
      {
        song: "Blank Space",
        link: "https://open.spotify.com/track/45wMBGri1PORPjM9PwFfrS?si=1d619d38acb241ae",
        month: 4,
        day: 15,
        about: "Hur media anser att hon är en slampa.",
      },
      {
        song: "Shake It Off",
        link: "https://open.spotify.com/track/3pv7Q5v2dpdefwdWIvE7yH?si=006162210547497d",
        month: 4,
        day: 17,
        about: "Haters som klaga på hennes dans.",
      },
      {
        song: "Wildest Dream",
        link: "https://open.spotify.com/track/1K39ty6o1sHwwlZwO6a7wK?si=df2f4560e42c4bd1",
        month: 3,
        day: 7,
        funfact: "Takten kommer från Taylors hjärtslag.",
      },
      {
        song: "Bad Blood",
        link: "https://open.spotify.com/track/64FzgoLZ3oXu2SriZblHic?si=7ca1810fc4e2465f",
        month: 4,
        day: 11,
        funfact: "Finns en remix hon gjorde tillsammans med Kendrik Lamar.",
        interaction:
          "Sjunga Kendrik Lamars del 'You forgive, you forget, but you never let it go' vid 2:23",
        about: "Katy Perry",
      },
    ],
  },

  {
    album: "Midnights",
    path: "/midnights",
    songs: [
      {
        song: "Lavender Haze",
        link: "https://open.spotify.com/track/5jQI2r1RdgtuT8S3iG8zFC?si=3b188c3b94054d88",
        month: 3,
        day: 29,
        about: "Joe Alwyn och att skydda deras relation från media.",
      },
      {
        song: "Anti-Hero",
        link: "https://open.spotify.com/track/0V3wPSX9ygBnCm8psDIegu?si=b8894210bd6b4efd",
        month: 4,
        day: 14,
        about: "Taylors egna självhat.",
      },
      {
        song: "Midnight Rain",
        link: "https://open.spotify.com/track/3rWDp9tBPQR9z6U5YyRSK4?si=00d1b56a8a704612",
        month: 3,
        day: 23,
        about: "En high school kärlek som inte ville att hon skulle bli känd.",
      },
      {
        song: "Vigilantie Shit",
        link: "https://open.spotify.com/track/1xwAWUI6Dj0WGC3KiUPN0O?si=8f150a357aeb4be9",
        month: 3,
        day: 12,
        lyrics:
          "While he was doing lines, and crossing all of mine. Someone told his white collar crimes to the FBI. And I don't dress for villains or for innocents, I'm on my vigilante shh again",
      },
      {
        song: "Bejewled",
        link: "https://open.spotify.com/track/3qoftcUZaUOncvIYjFSPdE?si=4a41c7b950854562",
        month: 3,
        day: 15,
        about:
          "Slutet på hennes relation med Calvin Harris och hintar om hur hon blev tillsammans Tom Hiddelston",
      },
      {
        song: "Mastermind",
        link: "https://open.spotify.com/track/56hioFjQ0DXrdn04hZcFgG?si=40de01dad81a4b91",
        month: 4,
        day: 9,
        lyrics:
          "No one wanted to play with me as a little kid. So I've been scheming like a criminal ever since, to make them love me and make it seem effortless",
      },
      {
        song: "Karma",
        link: "https://open.spotify.com/track/5oqDgw5VGBln5dSIOPWyBq?si=633310f078ae4a11",
        month: 3,
        day: 26,
        funfact:
          "När Travis Kelce (hennes nuvarande pojkvän som spelar för The Chiefs) är på konserten så byter hon ut texten 'Karma is the guy on TV' till 'Karma is the guy on the Chiefs'",
      },
    ],
  },
];
