import { Divider, Grid, styled } from "@mui/material";
import FearlessImage from "../Images/Fearless.svg";
import SpeakNowImage from "../Images/SpeakNow.svg";
import RedImage from "../Images/Red.svg";
import NinteenEightyNineImage from "../Images/1989.svg";
import ReputationImage from "../Images/Reputation.svg";
import LoverImage from "../Images/Lover.svg";
import FolkloreImage from "../Images/Folklore.svg";
import EvermoreImage from "../Images/Evermore.svg";
import MidnightsImage from "../Images/Midnights.svg";
import SNFull from "../Images/speak_now_full_opacity.png";
import FearlessFull from "../Images/fearless_full_opacity.png";
import LoverFull from "../Images/lover_full_opacity.png";
import FolkloreFull from "../Images/folklore_full_opacity.png";
import EvermoreFull from "../Images/evermore_full_opacity.png";
import MidnightsFull from "../Images/midnights_full_opacity.png";
import NinteenEightyNineFull from "../Images/1989_full_opacity.png";
import RedFull from "../Images/red_full_opacity.png";
import ReputationFull from "../Images/reputation_full_opacity.png";
import { Link as RLink } from "react-router-dom";

interface IPageContainer {
  era?:
    | "Fearless"
    | "Speak Now"
    | "Red"
    | "1989"
    | "reputation"
    | "Lover"
    | "folklore"
    | "evermore"
    | "Midnights";
  isMobile?: boolean;
}
export const PageContainer = styled(Grid, {
  shouldForwardProp: (prop) => !["era", "isMobile"].some((x) => x === prop),
})<IPageContainer>(({ era, isMobile, theme }) => ({
  backgroundSize: "cover",
  gap: "2rem",
  ...(era === "Fearless" && {
    backgroundImage: "url(" + FearlessFull + ")",
    ...(isMobile && {
      backgroundImage: "url(" + FearlessImage + ")",
    }),
    backgroundColor: "#473020",
  }),
  ...(era === "Speak Now" && {
    backgroundImage: "url(" + SNFull + ")",
    ...(isMobile && {
      backgroundImage: "url(" + SpeakNowImage + ")",
    }),
    backgroundColor: "rgba(0,0,0,1)",
  }),
  ...(era === "Red" && {
    backgroundImage: "url(" + RedFull + ")",
    ...(isMobile && {
      backgroundImage: "url(" + RedImage + ")",
    }),
  }),
  ...(era === "1989" && {
    backgroundImage: "url(" + NinteenEightyNineFull + ")",
    ...(isMobile && {
      backgroundImage: "url(" + NinteenEightyNineImage + ")",
    }),
  }),
  ...(era === "reputation" && {
    backgroundImage: "url(" + ReputationFull + ")",
    ...(isMobile && {
      backgroundImage: "url(" + ReputationImage + ")",
    }),
    backgroundColor: "black",
  }),
  ...(era === "Lover" && {
    backgroundImage: "url(" + LoverFull + ")",
    ...(isMobile && {
      backgroundImage: "url(" + LoverImage + ")",
    }),
  }),
  ...(era === "folklore" && {
    backgroundImage: "url(" + FolkloreFull + ")",
    ...(isMobile && {
      backgroundImage: "url(" + FolkloreImage + ")",
    }),
  }),
  ...(era === "evermore" && {
    backgroundImage: "url(" + EvermoreFull + ")",
    ...(isMobile && {
      backgroundImage: "url(" + EvermoreImage + ")",
    }),
  }),
  ...(era === "Midnights" && {
    backgroundImage: "url(" + MidnightsFull + ")",
    ...(isMobile && {
      backgroundImage: "url(" + MidnightsImage + ")",
    }),
  }),
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  height: "100vh",
  padding: "1rem",
  justifyContent: "flex-start",
  flexDirection: "column",
  alignItems: "center",
}));

interface IContentContainer {
  era?:
    | "Fearless"
    | "Speak Now"
    | "Red"
    | "1989"
    | "reputation"
    | "Lover"
    | "folklore"
    | "evermore"
    | "Midnights";
  isMobile?: boolean;
}
export const ContentContainer = styled(Grid, {
  shouldForwardProp: (prop) => !["era", "isMobile"].some((x) => x === prop),
})<IContentContainer>(({ era, isMobile, theme }) => ({
  borderRadius: "0.5rem",
  padding: "1rem",
  justifyContent: "center",
  flexDirection: "column",
  height: "fit-content",
  width: "90%",
  marginTop: "1rem",
  ...(!isMobile && {
    width: "50%",
  }),

  "& p": {
    color: "white",
    textAlign: "center",
  },
  ...(era === "Fearless" && {
    backgroundColor: "rgba(235,214,160,0.5)",
  }),
  ...(era === "Speak Now" && {
    backgroundColor: "rgba(131,59,129,0.4)",
  }),
  ...(era === "Red" && {
    backgroundColor: "rgba(115,15,9,0.9)",
  }),
  ...(era === "1989" && {
    backgroundColor: "rgba(145,176,213,0.5)",
  }),
  ...(era === "reputation" && {
    backgroundColor: "rgba(0,0,0,0.8)",
  }),
  ...(era === "Lover" && {
    backgroundColor: "rgba(234,173,214,0.7)",
  }),
  ...(era === "folklore" && {
    backgroundColor: "rgba(67,67,67,0.7)",
  }),
  ...(era === "evermore" && {
    backgroundColor: "rgba(83,47,32,0.8)",
  }),
  ...(era === "Midnights" && {
    backgroundColor: "rgba(22,30,55,0.9)",
  }),
}));

export const Line = styled(Divider)(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: "white",
  height: "1px",
}));

interface ILink {
  era?:
    | "Fearless"
    | "Speak Now"
    | "Red"
    | "1989"
    | "reputation"
    | "Lover"
    | "folklore"
    | "evermore"
    | "Midnights";
}
export const Link = styled("a", {
  shouldForwardProp: (prop) => prop !== "era",
})<ILink>(({ era, theme }) => ({
  color: "white",
  textDecoration: "none",
  textAlign: "center",
  height: "fit-content",
  marginTop: "1rem",
  "& img": {
    width: "60px",
  },
}));

export const ImageContainer = styled("img")(({ theme }) => ({
  boxShadow: "4px 4px 4px 0px rgba(0,0,0,0.12)",
  width: "300px",
}));

interface IReactLink {
  era?:
    | "Fearless"
    | "Speak Now"
    | "Red"
    | "1989"
    | "reputation"
    | "Lover"
    | "folklore"
    | "evermore"
    | "Midnights";
}

export const ReactLink = styled(RLink, {
  shouldForwardProp: (prop) => prop !== "era",
})<IReactLink>(({ era, theme }) => ({
  color: "white",
  borderRadius: "0.5rem",
  padding: "0.5rem",
  ...(era === "Fearless" && {
    backgroundColor: "rgba(235,214,160,0.5)",
  }),
  ...(era === "Speak Now" && {
    backgroundColor: "rgba(131,59,129,0.7)",
  }),
  ...(era === "Red" && {
    backgroundColor: "rgba(115,15,9,0.9)",
  }),
  ...(era === "1989" && {
    backgroundColor: "rgba(145,176,213,0.5)",
  }),
  ...(era === "reputation" && {
    backgroundColor: "rgba(0,0,0,0.8)",
  }),
  ...(era === "Lover" && {
    backgroundColor: "rgba(234,173,214,0.7)",
  }),
  ...(era === "folklore" && {
    backgroundColor: "rgba(67,67,67,0.7)",
  }),
  ...(era === "evermore" && {
    backgroundColor: "rgba(83,47,32,0.8)",
  }),
  ...(era === "Midnights" && {
    backgroundColor: "rgba(22,30,55,0.9)",
  }),
}));

export const TextContainer = styled(Grid)(({ theme }) => ({
  fontWeight: "normal",
}));
