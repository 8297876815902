import { Button, styled } from "@mui/material";
import { Link } from "react-router-dom";

interface IStyledButton {
  era?: string;
  disabled?: boolean;
  todaysButton?: boolean;
  isMobile?: boolean;
}
export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) =>
    !["era", "disabled", "todaysButton", "isMobile"].some((x) => x === prop),
})<IStyledButton>(({ era, disabled, todaysButton, isMobile, theme }) => ({
  color: "black",
  textDecoration: "none",
  textAlign: "center",
  height: "fit-content",
  backgroundColor: "white",
  padding: "2rem 2.5rem",
  [theme.breakpoints.down("md")]: {
    padding: "0.5rem 1.5rem",
  },
  ...(isMobile && {
    padding: "0.5rem 1rem",
  }),
  "& > svg": {
    color: "white",
    ...((disabled || todaysButton) && {
      color: "black",
    }),
  },
  ...(disabled && {
    backgroundColor: "rgba(225,225,225,0.5)",
  }),
  ...(era === "Fearless" &&
    !disabled && {
      backgroundColor: "rgba(235,214,160,0.9)",
      "&:hover": {
        backgroundColor: "rgba(178,163,122,0.9)",
      },
    }),
  ...(era === "Speak Now" &&
    !disabled && {
      backgroundColor: "rgba(131,59,129,0.9)",
      "&:hover": {
        backgroundColor: "rgba(77,34,75,0.9)",
      },
    }),
  ...(era === "Red" &&
    !disabled && {
      backgroundColor: "rgba(115,15,9,0.7)",
      "&:hover": {
        backgroundColor: "rgba(77,10,6,0.9)",
      },
    }),
  ...(era === "1989" &&
    !disabled && {
      backgroundColor: "rgba(145,176,213,0.7)",
      "&:hover": {
        backgroundColor: "rgba(88,120,158,0.9)",
      },
    }),
  ...(era === "reputation" &&
    !disabled && {
      backgroundColor: "rgba(0,0,0,0.7)",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.5)",
      },
    }),
  ...(era === "Lover" &&
    !disabled && {
      backgroundColor: "rgba(234,173,214,0.8)",
      "&:hover": {
        backgroundColor: "rgba(178,132,163,0.9)",
      },
    }),
  ...(era === "folklore" &&
    !disabled && {
      backgroundColor: "rgba(147,147,147,0.7)",
      "&:hover": {
        backgroundColor: "rgba(77,77,77,0.9)",
      },
    }),
  ...(era === "evermore" &&
    !disabled && {
      backgroundColor: "rgba(83,47,32,0.8)",
      "&:hover": {
        backgroundColor: "rgba(51,29,20,0.9)",
      },
    }),
  ...(era === "Midnights" &&
    !disabled && {
      backgroundColor: "rgba(22,30,55,1)",
      "&:hover": {
        backgroundColor: "rgba(15,21,38,0.9)",
      },
    }),
  ...(todaysButton && {
    backgroundColor: "white",
    color: "black",
    border: "1px solid black",
  }),
}));

interface IStyledLink {
  disabled?: boolean;
}
export const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== "disabled",
})<IStyledLink>(({ disabled, theme }) => ({
  ...(disabled && {
    pointerEvents: "none",
  }),
}));
