import { Grid, Typography, useMediaQuery } from "@mui/material";
import { FC } from "react";
import { useLocation } from "react-router-dom";
import SpotifyLogo from "../Images/Spotify_icon.svg";
import FearlessImage from "../Images/fearless_album.png";
import SpeakNowImage from "../Images/speak_now_album.png";
import RedImage from "../Images/red_album.png";
import NinteenEightyNineImage from "../Images/1989_album.png";
import ReputationImage from "../Images/reputation_album.png";
import LoverImage from "../Images/lover_album.png";
import FolkloreImage from "../Images/folklore_album.png";
import EvermoreImage from "../Images/evermore_album.png";
import MidnightsImage from "../Images/midnights_album.png";
import { Album } from "../SongData";
import theme from "../theme";
import {
  ContentContainer,
  ImageContainer,
  Line,
  Link,
  PageContainer,
  ReactLink,
  TextContainer,
} from "./Era.styles";
import { ArrowBack } from "@mui/icons-material";

interface IProps {
  songData?: Album;
}

export const Song: FC<IProps> = (props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  let { state } = useLocation();

  const getImage = () => {
    if (state.album === "Fearless") {
      return FearlessImage;
    }
    if (state.album === "Red") {
      return RedImage;
    }
    if (state.album === "Speak Now") {
      return SpeakNowImage;
    }
    if (state.album === "1989") {
      return NinteenEightyNineImage;
    }
    if (state.album === "reputation") {
      return ReputationImage;
    }
    if (state.album === "Lover") {
      return LoverImage;
    }
    if (state.album === "folklore") {
      return FolkloreImage;
    }
    if (state.album === "evermore") {
      return EvermoreImage;
    }
    if (state.album === "Midnights") {
      return MidnightsImage;
    }
  };

  return (
    <PageContainer container era={state.album} isMobile={isMobile}>
      <ContentContainer era={state.album} container isMobile={isMobile} gap={1}>
        <Grid container gap="0.5rem" alignItems="center">
          <Line />
          <Typography fontWeight={"normal"} fontSize={"1.5rem"}>
            {state.album}
          </Typography>
          <Line />
        </Grid>
        <Typography fontSize={isMobile ? "1.5rem" : "2rem"} fontWeight={"bold"}>
          {state.songs.song}
        </Typography>
        <Grid container={isMobile ? false : true}>
          {state.songs.about && (
            <TextContainer item flexBasis={0} flexGrow={1}>
              <Typography fontWeight={"bold"}>Handlar om: </Typography>
              <Typography fontWeight={"normal"}>{state.songs.about}</Typography>
            </TextContainer>
          )}
          {state.songs.interaction && (
            <TextContainer item flexBasis={0} flexGrow={1}>
              <Typography fontWeight={"bold"}>Konsert interaktion: </Typography>
              <Typography fontWeight={"normal"} textAlign={"left"}>
                {state.songs.interaction}
              </Typography>
            </TextContainer>
          )}
          {state.songs.funfact && (
            <TextContainer item flexBasis={0} flexGrow={1}>
              <Typography fontWeight={"bold"}>Fun fact: </Typography>
              <Typography fontWeight={"normal"}>
                {state.songs.funfact}
              </Typography>
            </TextContainer>
          )}
          {state.songs.lyrics && (
            <TextContainer item flexBasis={0} flexGrow={1}>
              <Typography fontWeight={"normal"} fontStyle={"italic"}>
                {state.songs.lyrics}
              </Typography>
            </TextContainer>
          )}
        </Grid>
        <Link href={state.songs.link} target="none">
          <img src={SpotifyLogo} alt="spotify-logo" />
        </Link>
      </ContentContainer>
      {!isMobile && (
        <>
          <ImageContainer alt="song" src={getImage()} />
          <ReactLink to={"/"} era={state.album}>
            <ArrowBack />
          </ReactLink>
        </>
      )}
    </PageContainer>
  );
};
