import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export const Thirteen = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2 0C0.895508 0 0 0.895416 0 2V22C0 23.1046 0.895508 24 2 24H22C23.1045 24 24 23.1046 24 22V2C24 0.895416 23.1045 0 22 0H2ZM8.26904 19V5.73999H4.28906V8.54001H5.34912V19H8.26904ZM18.7441 11.8C18.2373 11.16 17.5776 10.7533 16.7642 10.58L19.2842 5.73999H12.064V8.54001H15.2241L13.064 12.58L13.124 12.66C13.2974 12.6144 13.4585 12.5781 13.6069 12.5512C13.6753 12.5388 13.7412 12.5284 13.8042 12.52C14.0039 12.4933 14.2842 12.48 14.644 12.48C15.2173 12.48 15.6709 12.6533 16.0039 13C16.3374 13.3467 16.5039 13.8133 16.5039 14.4C16.5039 15 16.3174 15.48 15.9438 15.84C15.5708 16.1867 15.144 16.36 14.6641 16.36C14.2373 16.36 13.8174 16.2467 13.4043 16.02C12.9907 15.7933 12.6709 15.54 12.4438 15.26L10.9639 17.66C11.3906 18.1 11.9575 18.46 12.6641 18.74C13.3843 19.02 14.0776 19.16 14.7441 19.16C15.5972 19.16 16.3843 18.98 17.104 18.62C17.8242 18.2467 18.4043 17.7133 18.8442 17.02C19.2842 16.3267 19.5039 15.5133 19.5039 14.58C19.5039 13.3667 19.2505 12.44 18.7441 11.8Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};
